//React
import React from 'react'

//Gatsby
import { Link } from 'gatsby'

//Icons
import { MdArrowBack } from 'react-icons/md'

const Snippet = (props) => {
  const { color, category, categorylink, gradientColor, context } = props

  return (
    <div
      class={`gradient-callout-${gradientColor ? gradientColor : 'tropical'}`}
    >
      <div class="container">
        <div class="d-flex flex-row row my-2 pt-2 pb-1 px-4 white-1">
          <Link
            class={`${
              context === 'article' && 'font-weight-bold'
            } round-corners ml-0 p-1 pl-0 pr-2 small white-1 d-flex align-items-center`}
            to={`/`}
          >
            Support
          </Link>
          {context === 'article' && (
            <>
              &rsaquo;
              <Link
                class={`p-1 pl-2 pr-2 small white-1 d-flex align-items-center`}
                to={`/${categorylink}`}
              >
                {/* <MdArrowBack class="white-1 mr-1" /> */}
                {category}
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Snippet
