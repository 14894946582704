// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from 'react'
import { graphql, Link } from 'gatsby'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'

import Layout from '../components/layout'
import ListingTable from '../components/marketing/listingTable'
import Breadcrumb from '../components/marketing/breadcrumb'

import { FiArrowRight } from 'react-icons/fi'
import { FaArrowRight } from 'react-icons/fa'
import { MdArrowBack } from 'react-icons/md'

export default ({ data }) => {
  console.log('((data))')
  console.log(data)
  let post = data.sections
  // const sectionimages = data.sectionimages
  // const boatimagesfixed = data.boatimagesfixed

  const listingTable = {
    section: data.sections,
    articles: data.articles,
  }
  console.log('****listingTable.section')
  console.log(listingTable.section)
  // const listingTableMake = {
  //   boatreviews: data.boatreview,
  //   boatimagesfixed: boatimagesfixed,
  // }
  // const listingTableTags = {
  //   boatreviews: data.boattags,
  //   boatimagesfixed: boatimagesfixed,
  // }

  console.log('sectionTemplate - listingTable*****//')
  console.log(listingTable)
  // console.log('sectionimages*****//')
  // console.log(sectionimages)
  // console.log('boatimagesfixed*****//')
  // console.log(boatimagesfixed)

  const truncate = function (text, limit, after) {
    // Make sure an element and number of items to truncate is provided
    if (!text || !limit) return

    // Get the inner content of the element
    let content = text
    content = content.trim()

    // Convert the content into an array of words
    // Remove any words above the limit
    let wordcount = content.split(' ').length
    content = content.split(' ').slice(0, limit)

    // Convert the array of words back into a string
    // If there's content to add after it, add it
    content = content.join(' ') + (after && wordcount > limit ? after : '')

    // Inject the content back into the DOM
    return content
  }

  let gradientColor
  switch (listingTable.section.data.color) {
    case 'gray':
      gradientColor = 'slate'
      break
    case 'dkorange':
      gradientColor = 'fire'
      break
    case 'purple':
      gradientColor = 'tropical'
      break
    case 'pink':
      gradientColor = 'sunset'
      break
    case 'dkblue':
      gradientColor = 'cousteau'
      break
    case 'blue':
      gradientColor = 'bluesky'
      break
    case 'yellow':
      gradientColor = 'warm'
      break
    case 'bluegreen':
      gradientColor = 'deepsea'
      break
    case 'dkgreen':
      gradientColor = 'keywest'
      break
    case 'seafoam':
      gradientColor = 'seatosky'
      break
    default:
      gradientColor = 'cumulus'
  }

  console.log('sectionTemplate - gradientColor')
  console.log(gradientColor)
  return (
    <Layout>
      <Helmet
        title={`${post.data.title} - PicoNext`}
        meta={[
          {
            name: 'description',
            content: `${post.data.description}`,
          },
          {
            name: 'keywords',
            content: `${post.data.title}`,
          },
        ]}
      />
      <Breadcrumb
        context="section"
        // category={`Support Home`}
        // color={`purple`}
        // categorylink={``}
      />

      <div class="container my-2 my-4">
        <div class="row d-flex">
          {/* <div class="col-md-5 d-flex flex-row">
            {sectionimages.nodes[0] ? (
              <Img
                className="rounded-corners w-100"
                // style={{ minWidth: 240 }}
                fluid={sectionimages.nodes[0].childImageSharp.fluid}
              />
            ) : null}
          </div> */}
          {/* <Link
            class={`round-corners bg-gray-1 p-1 pl-2 pr-3 ml-3 mb-3  small white-1`}
            to={`/`}
          >
            <MdArrowBack class="white-1 mr-1" />
            Support Home
          </Link> */}
          {/* <hr class="my-2 pt-2" size={1} /> */}
          <div class="col-md-12 d-flex flex-column px-3 mt-2">
            <h1>{post.data.title}</h1>
            <p class="support-lead font-weight-light">
              {post?.data?.description}
            </p>
            {/* <p class="font-weight-light">{post.data.querymake}</p>
            <p class="font-weight-light">{post.data.querytags}</p> */}
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row d-flex">
          <div class="col-lg-12">
            <>
              {post.data.title ? (
                <ListingTable
                  data={listingTable}
                  gradientColor={gradientColor}
                  title={`Sort & filter ${post.data.title}`}
                  header={false}
                  padding={false}
                  inline={false}
                />
              ) : null}
              {/* <div class="container d-flex justify-content-center mt-4">
                <Link to="/boat/reviews/" class="btn btn-cta btn-primary">
                  More boat reviews <FaArrowRight />
                </Link>
              </div> */}
            </>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String!, $sectionid: [String]) {
    sections: airtable(id: { eq: $id }, table: { eq: "category" }) {
      id
      data {
        publish
        title
        description
        url
        color
      }
    }
    articles: allAirtable(
      filter: {
        table: { eq: "article" }
        data: { categorystring: { in: $sectionid }, publish: { eq: 1 } }
      }
      sort: { fields: data___sort, order: ASC }
    ) {
      edges {
        node {
          id
          data {
            title
            sort
            url
            category
            categorystring
            publish
            featured
            featuredicon
            markdown {
              childMdx {
                body
                headings {
                  depth
                  value
                }
              }
            }
          }
        }
      }
    }
  }
`
// make
//       model
//       review
//       year
//       style
//       rating
//       capacity
//       summaryattr
//       childMdx {
//         body
//       }
